/*
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited 2023.
 */

export const GTM_ID = 'google.tag.manager.container.id';
export const GTM_AUTH = 'google.tag.manager.auth';
export const GTM_PREVIEW = 'google.tag.manager.preview';
export const GA_VERSIONS = 'google.analytics.versions';
export const GA_VERSION_UA = 'UA';
export const GA_VERSION_GA4 = 'GA4';
export const PAGE_LOAD = 'pageLoad';
export const KEYWORD_SEARCH = 'keywordSearch';
export const NAVIGATION_CLICK = 'navigationClick';
export const FORM_COMPLETION = 'formCompletion';
export const PRODUCT_IMPRESSION = 'productImpression';
export const PRODUCT_CLICK = 'productClick';
export const PRODUCT_DETAIL = 'productDetail';
export const ADD_TO_CART = 'addToCart';
export const REMOVE_FROM_CART = 'removeFromCart';
export const PROMO_VIEW = 'promoView';
export const PROMOTION_CLICK = 'promotionClick';
export const PURCHASE = 'purchase';
export const CHECK_OUT = 'checkout';
export const CHECK_OUT_OPTION = 'checkoutOption';
export const HOME = 'Home';
export const ONSITE_SEARCH = 'Onsite Search';
export const LISTER = 'Lister';
export const PDP = 'PDP';
export const CHECKOUT = 'Checkout';
export const CONTENT = 'Content';
export const CART = 'Cart';
export const DISABLED_ESPOT_LIST = ['HeaderStoreLogo', 'FooterStoreLogo'];
export const GA4_EVENT_ADD_TO_CART = 'add_to_cart';
export const GA4_EVENT_REMOVE_FROM_CART = 'remove_from_cart';
export const GA4_EVENT_BEGIN_CHECKOUT = 'begin_checkout';
export const GA4_EVENT_ADD_PAYMENT_INFO = 'add_payment_info';
export const GA4_EVENT_ADD_SHIPPING_INFO = 'add_shipping_info';
export const GA4_EVENT_PURCHASE = 'GA4_purchase';
export const GA4_EVENT_VIEW_CART = 'view_cart';
export const GA4_EVENT_VIEW_PROMOTION = 'view_promotion';
export const GA4_EVENT_SELECT_PROMOTION = 'select_promotion';
export const GA4_EVENT_VIEW_ITEM_LIST = 'view_item_list';
export const GA4_EVENT_SELECT_ITEM = 'select_item';
export const GA4_EVENT_SEARCH = 'search';
export const GA4_EVENT_VIEW_SEARCH_RESULTS = 'view_search_results';
export const GA4_EVENT_SEARCH_TYPE_PRODUCT = 'product';
export const GA4_EVENT_VIEW_ITEM = 'view_item';
export const PAGE_DATA_LAYER = 'dataLayer';
export const CHUNK_SIZE = 20;
export const LOGGED_IN = 'logged In';
export const LOGGED_OUT = 'logged Out';
export const ACTIVITY_PRODUCT = 'product';
export const ACTIVITY_CATEGORY = 'category';
export const ACTIVITY_CONTENT = 'content';
