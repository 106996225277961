import { useNextRouter } from '@/data/Content/_NextRouter';
import { noop } from 'lodash';
import { createContext, FC, PropsWithChildren, useEffect, useState } from 'react';

export type HeaderAccountMenuContextType = {
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
};

export const HeaderAccountMenuContext = createContext<HeaderAccountMenuContextType>({
	isOpen: false,
	setIsOpen: noop,
});

export const HeaderAccountMenuProvider: FC<PropsWithChildren> = ({ children }) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const router = useNextRouter();

	useEffect(() => {
		const close = () => setIsOpen(false);

		router.events.on('routeChangeStart', close);

		return () => {
			router.events.off('routeChangeStart', close);
		};
	}, [router, setIsOpen]);

	return (
		<HeaderAccountMenuContext.Provider value={{ isOpen, setIsOpen }}>
			{children}
		</HeaderAccountMenuContext.Provider>
	);
};
