import { useNextRouter } from '@/data/Content/_NextRouter';
import { noop } from 'lodash';
import { createContext, FC, PropsWithChildren, useEffect, useState } from 'react';

export type GlobalSpinnerContextType = {
	isLoading: boolean;
	setIsLoading: (isLoading: boolean) => void;
};

export const GlobalSpinnerContext = createContext<GlobalSpinnerContextType>({
	isLoading: false,
	setIsLoading: noop,
});

export const GlobalSpinnerProvider: FC<PropsWithChildren> = ({ children }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const router = useNextRouter();

	useEffect(() => {
		const start = () => setIsLoading(true);
		const end = () => setIsLoading(false);

		router.events.on('routeChangeStart', start);
		router.events.on('routeChangeComplete', end);
		router.events.on('routeChangeError', end);

		return () => {
			router.events.off('routeChangeStart', start);
			router.events.off('routeChangeComplete', end);
			router.events.off('routeChangeError', end);
		};
	}, [router, setIsLoading]);

	return (
		<GlobalSpinnerContext.Provider value={{ isLoading, setIsLoading }}>
			{children}
		</GlobalSpinnerContext.Provider>
	);
};
