export const WACOAL_AMERICA_STORE_NAME = 'Wacoal';
export const WACOAL_CANADA_STORE_NAME = 'Wacoal Canada';
export const BTEMPTD_STORE_NAME = 'Btemptd';
// eslint-disable-next-line quotes
export const BTEMPTD_STORE_NAME_DISPLAY = "b.tempt'd by Wacoal";

export const WACOAL_AMERICA_STOREID = '10851';
export const WACOAL_CANADA_STOREID = '10853';
export const BTEMPTD_STOREID = '10852';

export const WACOAL_CANADA_STORE_TOKEN = 'wacoal-canada';
export const BTEMPTD_STORE_TOKEN = 'btemptd';

export const KLAVIYO_ID_10851 = 'QTuMC7';
export const KLAVIYO_ID_10852 = 'V2aAAQ';
export const KLAVIYO_ID_10853 = 'UJn5uD';
