export const BV_CLIENT_NAME_10851 = 'wacoal';
export const BV_CLIENT_NAME_10852 = 'wacoal';
export const BV_CLIENT_NAME_10853 = 'wacoal';

export const BV_SITE_ID_10851 = 'wacoal_us';
export const BV_SITE_ID_10852 = 'betempted_us';
export const BV_SITE_ID_10853 = 'wacoal_ca';

export const BV_LOCALE_10851 = 'en_US';
export const BV_LOCALE_10852 = 'en_US';
export const BV_LOCALE_10853 = 'en_CA';
